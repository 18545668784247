import { Grid, Typography } from "@material-ui/core"
import ProgressBar from "@ramonak/react-progress-bar"
import * as React from "react"
import { FC, useContext, useRef, useState } from "react"
import theme from "../../../App.theme"

import { Context as DAContext, IDatosAbiertosContextType, VariableType } from "../../../context/DatosAbiertosContext"

import useStyles from "./styles"

interface IFileUpload {
  referencia: VariableType
  title: string
  filename: string
}

const FileUpload: FC<IFileUpload> = ({ referencia, title, filename }) => {
  const styles = useStyles()
  const inputRef = useRef(null)
  const [progress, setProgress] = useState(null)
  const [done, setDone] = useState(false)

  const { uploadOpendataFile } = useContext<IDatosAbiertosContextType>(
    DAContext
  )

  const onChange = async (e: any) => {
    setDone(false)
    const file = e.target.files[0]

    if (file.name !== filename) {
      return
    }

    await uploadOpendataFile(referencia, file, progress => setProgress(progress))
    setProgress(null)
    setDone(true)
    inputRef.current.value = null
  }

  return (
    <Grid container direction="column" className={ styles.root }>
      <Grid container item className={ styles.inputWrapper }>
        <Grid item>
          <Typography variant="h6">{ title }</Typography>
        </Grid>
        <input ref={ inputRef } type="file" onChange={ onChange } />
        { progress && (
          <ProgressBar
            completed={ progress }
            width="300px"
            height="10px"
            margin="16px"
            baseBgColor={ theme.palette.primary.main }
            bgcolor={ theme.palette.extras.orange }
            isLabelVisible={ false }
          />
        ) }
        { done && (
          <Grid item className={ styles.doneMsg }>
            <Typography variant="body1">
              Carga correcta. Los datos están actualizados
            </Typography>
          </Grid>
        ) }
      </Grid>

      <Grid item>
        <Typography variant="body2" className={ styles.instructions }>
          El archvivo deberá de estar nombrado como { filename }
        </Typography>
      </Grid>
    </Grid>
  )
}

export default FileUpload
