import { makeStyles } from "@material-ui/core"
import { ITheme } from "../../../App.theme"


export default makeStyles((theme: ITheme) => ({

  root: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),

    borderBottom: `1px ${ theme.palette.text.disabled } solid`
  },


  inputWrapper: {
    alignItems: "center",

    "& h6": {
      margin: theme.spacing(2)
    }
  },

  doneMsg: {
    margin: theme.spacing(0, 4),
    color: theme.palette.status.success
  },

  instructions: {
    margin: theme.spacing(0, 2),
    color: theme.palette.text.disabled
  }
}))
