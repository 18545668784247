import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components"
// @ts-ignore
import moment from 'moment'

import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from "@aws-amplify/ui-react"
import { Grid, Typography } from "@material-ui/core"

import { ThemeProvider } from "@material-ui/styles"
import * as React from "react"
import { useEffect, useState } from "react"
import { ListNewslettersQuery, ListNewslettersQueryVariables } from "../API"
import theme from "../App.theme"
import { Button } from "../components/_common"
import { FileUpload } from "../components/AdminModule"
import { callGraphQL, GraphQLOptions } from "../context/contextHelpers"
import { Provider as DAContextProvider } from "../context/DatosAbiertosContext"

import { listNewsletters } from "../graphql/queries"
import { downloadCSV } from "../helpers/helperFunctions"

import { I18n } from "aws-amplify"
import internationalization from "../data/internationalization"
I18n.setLanguage("es")
I18n.putVocabularies(internationalization)

const AuthStateApp: React.FunctionComponent = () => {
  const [authState, setAuthState] = useState<AuthState>()
  const [user, setUser] = useState<object | undefined>()

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  const handleDownloadSubscribers = async () => {
    const subscribersData = await callGraphQL<ListNewslettersQuery,
      GraphQLOptions<ListNewslettersQueryVariables>>(listNewsletters, {
      variables: {
        // @ts-ignore
        limit: 10000
      }
    })

    const subscribers = subscribersData.data.listNewsletters.items
    .map(e => ({ email: e.email, fecha: moment(e.createdAt).format('DD-MM-YYYY') }))

    downloadCSV(subscribers, "Suscriptores-PNIF")
  }

  return authState === AuthState.SignedIn && user ? (
    <DAContextProvider>
      <ThemeProvider { ...{ theme } }>
        <main style={ { position: "relative" } }>
          <Grid container>
            <Grid item container justify="flex-end">
              <Grid item xs />
              <AmplifySignOut />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            style={ {
              alignItems: "center",
              padding: theme.spacing(8),
              height: "80vh"
            } }
          >
            <Grid item>
              <Grid container item direction={ "column" }>
                <Grid item style={ { margin: theme.spacing(4, 0) } }>
                  <Typography variant="h4">
                    Descarga de suscriptores boletín PNIF
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    aria="descargar suscriptores"
                    small
                    handleClick={ handleDownloadSubscribers }
                  >
                    Descargar CSV
                  </Button>
                </Grid>
              </Grid>


              <Grid container item>
                <Grid item style={ { margin: theme.spacing(4, 0) } }>
                  <Typography variant="h4">
                    Carga de archivos de datos Plataforma PNIF
                  </Typography>
                </Grid>

                <FileUpload
                  title={ "Base de datos municipios" }
                  referencia={ "opendata" }
                  filename={ "BD_Municipios_Portal.xlsx" }
                />

                <FileUpload
                  title={ "Base de datos históricos municipios" }
                  referencia={ "historico" }
                  filename={ "Historico_trimestrales_Portal.xlsx" }
                />

                <FileUpload
                  title={ "ENIF" }
                  referencia={ "enif" }
                  filename={ "ENIF_2018_Din_Portal.xlsx" }
                />

                <FileUpload
                  title={ "Indicadores" }
                  referencia={ "indicadores" }
                  filename={ "Indicadores.xlsx" }
                />
              </Grid>

            </Grid>
          </Grid>
        </main>
      </ThemeProvider>
    </DAContextProvider>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp />
    </AmplifyAuthenticator>
  )
}

export default AuthStateApp
